@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes toggle {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(0.15873rem);
  }
}
.margin-top-2 {
  margin-top: 0.01587rem;
}

.padding-top-2 {
  padding-top: 0.01587rem;
}

.margin-bottom-2 {
  margin-bottom: 0.01587rem;
}

.padding-bottom-2 {
  padding-bottom: 0.01587rem;
}

.margin-left-2 {
  margin-left: 0.01587rem;
}

.padding-left-2 {
  padding-left: 0.01587rem;
}

.margin-right-2 {
  margin-right: 0.01587rem;
}

.padding-right-2 {
  padding-right: 0.01587rem;
}

.margin-top-3 {
  margin-top: 0.02381rem;
}

.padding-top-3 {
  padding-top: 0.02381rem;
}

.margin-bottom-3 {
  margin-bottom: 0.02381rem;
}

.padding-bottom-3 {
  padding-bottom: 0.02381rem;
}

.margin-left-3 {
  margin-left: 0.02381rem;
}

.padding-left-3 {
  padding-left: 0.02381rem;
}

.margin-right-3 {
  margin-right: 0.02381rem;
}

.padding-right-3 {
  padding-right: 0.02381rem;
}

.margin-top-4 {
  margin-top: 0.03175rem;
}

.padding-top-4 {
  padding-top: 0.03175rem;
}

.margin-bottom-4 {
  margin-bottom: 0.03175rem;
}

.padding-bottom-4 {
  padding-bottom: 0.03175rem;
}

.margin-left-4 {
  margin-left: 0.03175rem;
}

.padding-left-4 {
  padding-left: 0.03175rem;
}

.margin-right-4 {
  margin-right: 0.03175rem;
}

.padding-right-4 {
  padding-right: 0.03175rem;
}

.margin-top-5 {
  margin-top: 0.03968rem;
}

.padding-top-5 {
  padding-top: 0.03968rem;
}

.margin-bottom-5 {
  margin-bottom: 0.03968rem;
}

.padding-bottom-5 {
  padding-bottom: 0.03968rem;
}

.margin-left-5 {
  margin-left: 0.03968rem;
}

.padding-left-5 {
  padding-left: 0.03968rem;
}

.margin-right-5 {
  margin-right: 0.03968rem;
}

.padding-right-5 {
  padding-right: 0.03968rem;
}

.margin-top-6 {
  margin-top: 0.04762rem;
}

.padding-top-6 {
  padding-top: 0.04762rem;
}

.margin-bottom-6 {
  margin-bottom: 0.04762rem;
}

.padding-bottom-6 {
  padding-bottom: 0.04762rem;
}

.margin-left-6 {
  margin-left: 0.04762rem;
}

.padding-left-6 {
  padding-left: 0.04762rem;
}

.margin-right-6 {
  margin-right: 0.04762rem;
}

.padding-right-6 {
  padding-right: 0.04762rem;
}

.margin-top-7 {
  margin-top: 0.05556rem;
}

.padding-top-7 {
  padding-top: 0.05556rem;
}

.margin-bottom-7 {
  margin-bottom: 0.05556rem;
}

.padding-bottom-7 {
  padding-bottom: 0.05556rem;
}

.margin-left-7 {
  margin-left: 0.05556rem;
}

.padding-left-7 {
  padding-left: 0.05556rem;
}

.margin-right-7 {
  margin-right: 0.05556rem;
}

.padding-right-7 {
  padding-right: 0.05556rem;
}

.margin-top-8 {
  margin-top: 0.06349rem;
}

.padding-top-8 {
  padding-top: 0.06349rem;
}

.margin-bottom-8 {
  margin-bottom: 0.06349rem;
}

.padding-bottom-8 {
  padding-bottom: 0.06349rem;
}

.margin-left-8 {
  margin-left: 0.06349rem;
}

.padding-left-8 {
  padding-left: 0.06349rem;
}

.margin-right-8 {
  margin-right: 0.06349rem;
}

.padding-right-8 {
  padding-right: 0.06349rem;
}

.margin-top-10 {
  margin-top: 0.07937rem;
}

.padding-top-10 {
  padding-top: 0.07937rem;
}

.margin-bottom-10 {
  margin-bottom: 0.07937rem;
}

.padding-bottom-10 {
  padding-bottom: 0.07937rem;
}

.margin-left-10 {
  margin-left: 0.07937rem;
}

.padding-left-10 {
  padding-left: 0.07937rem;
}

.margin-right-10 {
  margin-right: 0.07937rem;
}

.padding-right-10 {
  padding-right: 0.07937rem;
}

.margin-top-12 {
  margin-top: 0.09524rem;
}

.padding-top-12 {
  padding-top: 0.09524rem;
}

.margin-bottom-12 {
  margin-bottom: 0.09524rem;
}

.padding-bottom-12 {
  padding-bottom: 0.09524rem;
}

.margin-left-12 {
  margin-left: 0.09524rem;
}

.padding-left-12 {
  padding-left: 0.09524rem;
}

.margin-right-12 {
  margin-right: 0.09524rem;
}

.padding-right-12 {
  padding-right: 0.09524rem;
}

.margin-top-15 {
  margin-top: 0.11905rem;
}

.padding-top-15 {
  padding-top: 0.11905rem;
}

.margin-bottom-15 {
  margin-bottom: 0.11905rem;
}

.padding-bottom-15 {
  padding-bottom: 0.11905rem;
}

.margin-left-15 {
  margin-left: 0.11905rem;
}

.padding-left-15 {
  padding-left: 0.11905rem;
}

.margin-right-15 {
  margin-right: 0.11905rem;
}

.padding-right-15 {
  padding-right: 0.11905rem;
}

.margin-top-16 {
  margin-top: 0.12698rem;
}

.padding-top-16 {
  padding-top: 0.12698rem;
}

.margin-bottom-16 {
  margin-bottom: 0.12698rem;
}

.padding-bottom-16 {
  padding-bottom: 0.12698rem;
}

.margin-left-16 {
  margin-left: 0.12698rem;
}

.padding-left-16 {
  padding-left: 0.12698rem;
}

.margin-right-16 {
  margin-right: 0.12698rem;
}

.padding-right-16 {
  padding-right: 0.12698rem;
}

.margin-top-18 {
  margin-top: 0.14286rem;
}

.padding-top-18 {
  padding-top: 0.14286rem;
}

.margin-bottom-18 {
  margin-bottom: 0.14286rem;
}

.padding-bottom-18 {
  padding-bottom: 0.14286rem;
}

.margin-left-18 {
  margin-left: 0.14286rem;
}

.padding-left-18 {
  padding-left: 0.14286rem;
}

.margin-right-18 {
  margin-right: 0.14286rem;
}

.padding-right-18 {
  padding-right: 0.14286rem;
}

.margin-top-20 {
  margin-top: 0.15873rem;
}

.padding-top-20 {
  padding-top: 0.15873rem;
}

.margin-bottom-20 {
  margin-bottom: 0.15873rem;
}

.padding-bottom-20 {
  padding-bottom: 0.15873rem;
}

.margin-left-20 {
  margin-left: 0.15873rem;
}

.padding-left-20 {
  padding-left: 0.15873rem;
}

.margin-right-20 {
  margin-right: 0.15873rem;
}

.padding-right-20 {
  padding-right: 0.15873rem;
}

.margin-top-24 {
  margin-top: 0.19048rem;
}

.padding-top-24 {
  padding-top: 0.19048rem;
}

.margin-bottom-24 {
  margin-bottom: 0.19048rem;
}

.padding-bottom-24 {
  padding-bottom: 0.19048rem;
}

.margin-left-24 {
  margin-left: 0.19048rem;
}

.padding-left-24 {
  padding-left: 0.19048rem;
}

.margin-right-24 {
  margin-right: 0.19048rem;
}

.padding-right-24 {
  padding-right: 0.19048rem;
}

.margin-top-25 {
  margin-top: 0.19841rem;
}

.padding-top-25 {
  padding-top: 0.19841rem;
}

.margin-bottom-25 {
  margin-bottom: 0.19841rem;
}

.padding-bottom-25 {
  padding-bottom: 0.19841rem;
}

.margin-left-25 {
  margin-left: 0.19841rem;
}

.padding-left-25 {
  padding-left: 0.19841rem;
}

.margin-right-25 {
  margin-right: 0.19841rem;
}

.padding-right-25 {
  padding-right: 0.19841rem;
}

.margin-top-28 {
  margin-top: 0.22222rem;
}

.padding-top-28 {
  padding-top: 0.22222rem;
}

.margin-bottom-28 {
  margin-bottom: 0.22222rem;
}

.padding-bottom-28 {
  padding-bottom: 0.22222rem;
}

.margin-left-28 {
  margin-left: 0.22222rem;
}

.padding-left-28 {
  padding-left: 0.22222rem;
}

.margin-right-28 {
  margin-right: 0.22222rem;
}

.padding-right-28 {
  padding-right: 0.22222rem;
}

.margin-top-30 {
  margin-top: 0.2381rem;
}

.padding-top-30 {
  padding-top: 0.2381rem;
}

.margin-bottom-30 {
  margin-bottom: 0.2381rem;
}

.padding-bottom-30 {
  padding-bottom: 0.2381rem;
}

.margin-left-30 {
  margin-left: 0.2381rem;
}

.padding-left-30 {
  padding-left: 0.2381rem;
}

.margin-right-30 {
  margin-right: 0.2381rem;
}

.padding-right-30 {
  padding-right: 0.2381rem;
}

.margin-top-32 {
  margin-top: 0.25397rem;
}

.padding-top-32 {
  padding-top: 0.25397rem;
}

.margin-bottom-32 {
  margin-bottom: 0.25397rem;
}

.padding-bottom-32 {
  padding-bottom: 0.25397rem;
}

.margin-left-32 {
  margin-left: 0.25397rem;
}

.padding-left-32 {
  padding-left: 0.25397rem;
}

.margin-right-32 {
  margin-right: 0.25397rem;
}

.padding-right-32 {
  padding-right: 0.25397rem;
}

.margin-top-35 {
  margin-top: 0.27778rem;
}

.padding-top-35 {
  padding-top: 0.27778rem;
}

.margin-bottom-35 {
  margin-bottom: 0.27778rem;
}

.padding-bottom-35 {
  padding-bottom: 0.27778rem;
}

.margin-left-35 {
  margin-left: 0.27778rem;
}

.padding-left-35 {
  padding-left: 0.27778rem;
}

.margin-right-35 {
  margin-right: 0.27778rem;
}

.padding-right-35 {
  padding-right: 0.27778rem;
}

.margin-top-40 {
  margin-top: 0.31746rem;
}

.padding-top-40 {
  padding-top: 0.31746rem;
}

.margin-bottom-40 {
  margin-bottom: 0.31746rem;
}

.padding-bottom-40 {
  padding-bottom: 0.31746rem;
}

.margin-left-40 {
  margin-left: 0.31746rem;
}

.padding-left-40 {
  padding-left: 0.31746rem;
}

.margin-right-40 {
  margin-right: 0.31746rem;
}

.padding-right-40 {
  padding-right: 0.31746rem;
}

.margin-top-50 {
  margin-top: 0.39683rem;
}

.padding-top-50 {
  padding-top: 0.39683rem;
}

.margin-bottom-50 {
  margin-bottom: 0.39683rem;
}

.padding-bottom-50 {
  padding-bottom: 0.39683rem;
}

.margin-left-50 {
  margin-left: 0.39683rem;
}

.padding-left-50 {
  padding-left: 0.39683rem;
}

.margin-right-50 {
  margin-right: 0.39683rem;
}

.padding-right-50 {
  padding-right: 0.39683rem;
}

.margin-top-60 {
  margin-top: 0.47619rem;
}

.padding-top-60 {
  padding-top: 0.47619rem;
}

.margin-bottom-60 {
  margin-bottom: 0.47619rem;
}

.padding-bottom-60 {
  padding-bottom: 0.47619rem;
}

.margin-left-60 {
  margin-left: 0.47619rem;
}

.padding-left-60 {
  padding-left: 0.47619rem;
}

.margin-right-60 {
  margin-right: 0.47619rem;
}

.padding-right-60 {
  padding-right: 0.47619rem;
}

.margin-top-70 {
  margin-top: 0.55556rem;
}

.padding-top-70 {
  padding-top: 0.55556rem;
}

.margin-bottom-70 {
  margin-bottom: 0.55556rem;
}

.padding-bottom-70 {
  padding-bottom: 0.55556rem;
}

.margin-left-70 {
  margin-left: 0.55556rem;
}

.padding-left-70 {
  padding-left: 0.55556rem;
}

.margin-right-70 {
  margin-right: 0.55556rem;
}

.padding-right-70 {
  padding-right: 0.55556rem;
}

.margin-top-100 {
  margin-top: 0.79365rem;
}

.padding-top-100 {
  padding-top: 0.79365rem;
}

.margin-bottom-100 {
  margin-bottom: 0.79365rem;
}

.padding-bottom-100 {
  padding-bottom: 0.79365rem;
}

.margin-left-100 {
  margin-left: 0.79365rem;
}

.padding-left-100 {
  padding-left: 0.79365rem;
}

.margin-right-100 {
  margin-right: 0.79365rem;
}

.padding-right-100 {
  padding-right: 0.79365rem;
}

.w60 {
  width: 0.47619rem;
}

.minw60 {
  min-width: 0.47619rem;
}

.w70 {
  width: 0.55556rem;
}

.minw70 {
  min-width: 0.55556rem;
}

.w80 {
  width: 0.63492rem;
}

.minw80 {
  min-width: 0.63492rem;
}

.w90 {
  width: 0.71429rem;
}

.minw90 {
  min-width: 0.71429rem;
}

.w100 {
  width: 0.79365rem;
}

.minw100 {
  min-width: 0.79365rem;
}

.w110 {
  width: 0.87302rem;
}

.minw110 {
  min-width: 0.87302rem;
}

.w120 {
  width: 0.95238rem;
}

.minw120 {
  min-width: 0.95238rem;
}

.w130 {
  width: 1.03175rem;
}

.minw130 {
  min-width: 1.03175rem;
}

.w140 {
  width: 1.11111rem;
}

.minw140 {
  min-width: 1.11111rem;
}

.w150 {
  width: 1.19048rem;
}

.minw150 {
  min-width: 1.19048rem;
}

.w160 {
  width: 1.26984rem;
}

.minw160 {
  min-width: 1.26984rem;
}

.w170 {
  width: 1.34921rem;
}

.minw170 {
  min-width: 1.34921rem;
}

.w180 {
  width: 1.42857rem;
}

.minw180 {
  min-width: 1.42857rem;
}

.w190 {
  width: 1.50794rem;
}

.minw190 {
  min-width: 1.50794rem;
}

.w200 {
  width: 1.5873rem;
}

.minw200 {
  min-width: 1.5873rem;
}

.w300 {
  width: 2.38095rem;
}

.minw300 {
  min-width: 2.38095rem;
}

.w400 {
  width: 3.1746rem;
}

.minw400 {
  min-width: 3.1746rem;
}

.w500 {
  width: 3.96825rem;
}

.minw500 {
  min-width: 3.96825rem;
}

.w600 {
  width: 4.7619rem;
}

.minw600 {
  min-width: 4.7619rem;
}

.w700 {
  width: 5.55556rem;
}

.minw700 {
  min-width: 5.55556rem;
}

.w800 {
  width: 6.34921rem;
}

.minw800 {
  min-width: 6.34921rem;
}

.w900 {
  width: 7.14286rem;
}

.minw900 {
  min-width: 7.14286rem;
}

.w1000 {
  width: 7.93651rem;
}

.minw1000 {
  min-width: 7.93651rem;
}

.w265 {
  width: 2.10317rem;
}

.font10 {
  font-size: 0.07937rem;
}

.font12 {
  font-size: 0.09524rem;
}

.font13 {
  font-size: 0.10317rem;
}

.font14 {
  font-size: 0.11111rem;
}

.font16 {
  font-size: 0.12698rem;
}

.font18 {
  font-size: 0.14286rem;
}

.font20 {
  font-size: 0.15873rem;
}

.font22 {
  font-size: 0.1746rem;
}

.font24 {
  font-size: 0.19048rem;
}

.font26 {
  font-size: 0.20635rem;
}

.font28 {
  font-size: 0.22222rem;
}

.font30 {
  font-size: 0.2381rem;
}

.font32 {
  font-size: 0.25397rem;
}

.font34 {
  font-size: 0.26984rem;
}

.font36 {
  font-size: 0.28571rem;
}

.font38 {
  font-size: 0.30159rem;
}

.font40 {
  font-size: 0.31746rem;
}

:root {
  --white: #fff;
  --black: #000;
  --grey: #5e5e5e;
  --red: #db001c;
  --dark-grey-color: #6b7694;
  --grey-border: #777995;
  --base-grey: #09002a;
  --golden-color: #f4cf00;
  --pale-grey-color: #a5a7c2;
  --main-color: #1a90ff;
  --base-gutter: 0.07937rem;
  --medium-gutter: 0.12698rem;
  --modalz-index: 9999;
  --tipz-index: 999;
  --image-placeholder-color: rgba(0, 0, 0, 0.5);
  --header-title-font-size: 0.19048rem;
  --game-title-font-size: 0.15873rem;
}

.lighter {
  --primary-color: #1a9fffff;
  --success-color: #7fcc66ff;
  --warning-color: #ffd500ff;
  --danger-color: #ff3333ff;
  --pink-color: #e83e98;
  --primary-color-change: (--primary-color, #4cb5ffff, #0086e5ff, #003c66ff);
  --success-color-change: (--success-color, #87e467ff, #75ba5eff, #2b4224ff);
  --warning-color-change: (--warning-color, #ffdd33, #ccaa00, #aa9900);
  --danger-color-change: (--danger-color, #ff4c4dff, #f20d0dff, #5f0103ff);
  --pink-color-change: (--pink-color, #ec65ad, #ba327a, #98107a);
  --reverse-color: #fff;
  --text-color: #ece8f8;
  --scroll-color: rgba(#999, 0.5);
  --scroll-hover-color: rgba(#999, 0.8);
  --base-border-radius: 0.06349rem;
  --medium-border-radius: 0.09524rem;
  --larger-border-radius: 0.12698rem;
  --bg-color: transparent;
  --bg-color-from: transparent;
  --bg-color-to: transparent;
  --modal-bg-color: #15446b;
  --modal-bg-color-from: #15446b;
  --modal-bg-color-to: #b6436a;
  --modal-border-color: #b6b8cd;
}

.fade-enter,
.fade-appear {
  opacity: 0;
}

.fade-enter-active,
.fade-appear-active {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.fade-exit-done {
  opacity: 0;
}

.container .linkButton {
  font-size: 0.11111rem;
  min-width: 0.79365rem;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: uppercase;
}
.container .item {
  position: relative;
  font-size: 0.11111rem;
  height: 0.39683rem;
  cursor: pointer;
  color: #fff;
  text-align: center;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: all 0.1s ease-in-out;
  min-width: 0.63492rem;
  background-color: #2b3046;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.04762rem;
  letter-spacing: 0.00397rem;
  border-radius: var(--medium-border-radius);
  font-weight: bold;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.container .item:hover {
  font-size: 0.12698rem;
}
.container .item:active {
  text-shadow: 0rem 0.02381rem 0.07937rem rgba(31, 35, 51, 0.6);
}